<template>
  <ValidationProvider
    ref="fieldCurrency"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iPriceListId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :menu-props="{ offsetY: true }"
        :success="valid"
        clearable
        dense
        hide-details
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { PriceListCollection } from "@planetadeleste/vue-mc-gw";
import { castArray, map, set } from "lodash";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class PriceListSelect extends Vue {
  @VModel({ type: Number }) iPriceListId!: number;
  @Prop({ type: String, default: "pricelist" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly autoselect!: boolean;
  @Prop([Number, Array]) disabledId!: number | number[];

  obCollection: PriceListCollection = new PriceListCollection();

  get items() {
    const arItems = this.obCollection.getModelList();

    return this.arDisableIdList.length
      ? map(arItems, (obItem) => {
          if (this.arDisableIdList.includes(obItem.id)) {
            set(obItem, "disabled", true);
          }

          return obItem;
        })
      : arItems;
  }

  get loading() {
    return this.obCollection.loading;
  }

  get arDisableIdList(): number[] {
    return this.disabledId ? castArray(this.disabledId) : [];
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.obCollection.clear();
    await this.obCollection.fetch();

    if (this.autoselect && this.obCollection.length) {
      if (this.iPriceListId) {
        this.onChange(this.iPriceListId);
        return;
      }

      const obModel =
        this.obCollection.length > 1
          ? this.obCollection.find({ is_default: true })
          : this.obCollection.first();

      if (obModel) {
        this.$emit("input", obModel.id);
        this.onChange(obModel.id);
      }
    }
  }

  onChange(iValue: number) {
    const obModel = this.obCollection.find({ id: iValue });
    this.$emit("change", obModel);
  }
}
</script>
